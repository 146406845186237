import React from "react"
import './partyhall.css'
import tw from "twin.macro"

function PartyHall() {
  const partyMenu = [
    {
      title: "NORTH DELUX BUFFET",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Choice Of 2 Sweet",
        "Carrot Halwa / Kashi Halwa",
        "Choice Of Soup",
        "North Papad",
        "Green Salad",
        "2 type Dry Starters(1 Tandoori & 1 Chinese)",
        "Roti / Kulcha/naan / Rumali Roti",
        "Dal Fry",
        "Dry / Sami Gravy Curry",
        "Spcial Curry",
        "Pulao Or Biriyani Or Dal Khichdi",
        "Raith",
        "Plain Rice",
        "Rasam",
        "Curd / Curd Rice",
        "Fruits Custard / Fruits Salad",
        "Banana",
        "Ice Cream",
        "Beeda",
        "Water Bottle 300ml",
      ],
      price: "580",
    },
    {
      title: "NORTH BUFFET",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Sweet / Carrot Halwa / Kashi Halwa / Dry Jamoon",
        "North Papad",
        "Green Salad",
        "Gobi / Baby Corn Manchurian",
        "Roti / Kulcha",
        "Dal Fry",
        "Special Curry",
        "Veg Pulao / Biriyani/veg Fried Rice / Jeera Rice",
        "Plain Rice",
        "Rasam",
        "Curd / Curd Rice",
        "Ice Cream",
        "Bananna",
        "Water Bottle 300ml",
      ],
      price: "410",
    },
    {
      title: "NORTH SPECIAL BUFFET",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Sweet",
        "Carrot Halwa / Kashi Halwa",
        "Soup",
        "North Papad",
        "Green Salad",
        "Aloo Bonda/samosa",
        "Gobi /baby Corn / Aloo Manchuri",
        "Roti / Naan / Chapati / Rumali Roti",
        "Dal Fry",
        "Dry / Sami Gravey Curry",
        "Special Curry",
        "North Rice With Raitha",
        "Plain Rice",
        "Rasam",
        "Curd / Curd Rice",
        "Ice Cream",
        "Fruit Custard / Fruit Salad",
        "Water Bottle 300ml",
        "Beeda",
      ],
      price: "490",
    },
    {
      title: "SOUTH NORTH MIX BUFFET",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Jamoon / Halwa",
        "Holige",
        "Pickle",
        "Salt",
        "Tomato / Sweet Corn Soup",
        "North Papad / Fryums",
        "Gobi / Baby Corn Starter",
        "Veg Biriyani / Veg Fried Rice",
        "Puri / Chapathi",
        "Kurma/saagu",
        "Plain Rice",
        "Rasam",
        "Curd / Curd Rice",
        "Ice Cream",
        "Banana",
        "Beeda",
        "Water Bottle 300ml",
      ],
      price: 390,
    },
    {
      title: "SOUTH INDIAN SPECILITY PLANTAIN LEAF MEALS MENU",
      menu: [
        "Welcome Drink (any One)",
        "Lemon Juice / Orange Juice / Sherbat /mango Juice / Watermelon",
        "Pickles",
        "Salt",
        "Papad / Fryums",
        "Holige",
        "Payasa Any One",
        "Sweet / Jahangir / Cham Cham / Rasamalai /Mothichur Laddu / Gulab Jamoon Any 1type",
        "Masala Vada / Bajji / Badnekai Tava Fry / Pakoda",
        "Chutney",
        "Kosambri Any One",
        "Pallya 2type",
        "Bendi Fry / Aloo Dry",
        "Puri / Akkiroti / Raagi Roti / Mini Dosa Any One",
        "Kurma / Saagu",
        "Ricebath / veg Pulao / Biriyani",
        "Raitha",
        "White rice",
        "Sambar / Pappu",
        "Rasam",
        "Curd / Curd Rice",
        "Custard Fruits / Fruits Salad",
        "Ice Cream",
        "Banana",
        "Beeda",
        "Water Bottle 300ml",
      ],
      price: 520,
    },
    {
      title: "SOUTH PLAINTANE LEAF MEAL",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Sweet Any One",
        "Payasa",
        "Papad / Fryums",
        "Kosambri",
        "Palya 2 Type",
        "Puri / Chapati / Rumali Roti",
        "Saagu / Kurma / Curry",
        "Pulao / Rice Bath",
        "Raitha / Chutney",
        "White Rice",
        "Sambar / Pappu",
        "Rasam",
        "Curd / Curd Rice",
        "Banana",
        "Icecream",
        "Beeda",
        "Water Bottle 300ml",
      ],
      price: 380,
    },
    {
      title: "SOUTH INDIAN BUFFET",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Payasa",
        "Sweet Any One",
        "Papad",
        "Kosambri",
        "Palya",
        "Bajji / Pakoda",
        "Puri / Chapati",
        "Saagu / kurma",
        "Ricebath Any One",
        "Raitha",
        "Plain Rice",
        "Sambar",
        "Rasam",
        "Curd / curd Rice",
        "beeda",
        "Ice Cream",
        "Water Bottle 300ml",
      ],
      price: 350,
    },
    {
      title: "SOUTH BUFFET MENU",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Payasa",
        "Sweet (any One)",
        "Papad",
        "Kosamberi",
        "Pallya",
        "Alu Bonda / Maddurvada /",
        "Masala Vada",
        "Puri / Mini Masala Dosa",
        "Ragi Dosa / Ragi Rotti",
        "Kurma / Saagu",
        "Ricebath Any One",
        "Raitha/chutney",
        "Plain Rice",
        "Rasam / Sambar",
        "Curd / Curd Rice",
        "Icecream",
        "Banana",
        "Water Bottle 300ml",
      ],
      price: 320,
    },
    {
      title: "HIGH TEA PARTY MENU",
      menu: [
        "Welcome Drinks",
        "Pickles",
        "Salt",
        "Sweet",
        "Wafers Chips / Kurkure",
        "Aloo Bonda /Maddurvada /Veg Samosa (Any One)",
        "Bhelpuri / Masala Puri /",
        "Sevpuri / Any One",
        "Rava Idly / Mini Idly",
        "Mini Masala Dosa / Akki Rotti",
        "Coffe / Tea / Badam Milk",
      ],
      price: 260,
    },
    {
      title: "SPECIAL BREAKFAST MENU",
      menu: [
        "Kesari Bath / Kashi /",
        "Carrot Halwa",
        "Idly / Mini Idly",
        "Vada",
        "Pongal / Kharabath / Shavige Bath",
        "Mini Masala Dosa / Poori",
        "Coffee / Tea / Badam Milk",
      ],
      price: 220,
    },
    {
      title: "BREAKFAST MENU",
      menu: [
        "Idly",
        "Vada",
        "Kesaribath / Carrot Halwa",
        "Pongal",
        "Coffe / Tea",
      ],
      price: 180,
    },
  ]
   const Subheading = tw.h3`font-black text-3xl md:text-3xl leading-snug max-w-3xl self-center`
   const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`
  return (
    <div className='main'>
      <span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Subheading>
          Party Hall / Catering <HighlightedText>Menu</HighlightedText>
        </Subheading>
      </span>
      <ul className='cards'>
        {partyMenu.map((item, index) => (
          <li className='cards_item' key={index}>
            {console.log(index)}
            <div className='card'>
              <h2 className='card_title'>{item.title}</h2>
              <div className='card_image'>
                <img src={`images/partyhall/${index}.jpg`} alt={item.title} />
                <div className='price'>Rs{item.price}/plt</div>
              </div>
              <div className='card_content'>
                <div className='card_text'>
                  <ul>
                    {item.menu.map((m, index) => (
                      <li key={index}>-{m}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )

}

export default PartyHall
