import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { SectionHeading } from "../misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
// import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer p-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    "HOT BEVERAGES": [
      { imageSrc: "images/1/1.jpg", title: "Tea / coffee", price: "35.00" },
      { imageSrc: "images/1/2.jpg", title: "Badam milk", price: "50.00" },
      { imageSrc: "images/1/3.jpg", title: "bournvita", price: "50.00" },
      { imageSrc: "images/1/4.jpg", title: "Black tea", price: "35.00" },
      { imageSrc: "images/1/5.jpg", title: "Lemon tea", price: "40.00" },
      { imageSrc: "images/1/6.jpg", title: "Masala tea", price: "40.00" },
      { imageSrc: "images/1/7.jpg", title: "Horlicks", price: "50.00" },
      { imageSrc: "images/1/8.jpg", title: "Ginger Tea", price: "45.00" },
      { imageSrc: "images/1/9.jpg", title: "Kashaya", price: "45.00" },
      {
        imageSrc: "images/1/10.jpg",
        title: "Jaggery Coffee / Tea",
        price: "40.00"
      }
    ],
    "SOUTH INDIAN DISHES": [
      { imageSrc: "images/2/1.jpg", title: "Idly (2)", price: "50.00" },
      { imageSrc: "images/2/2.jpg", title: "Idly(1)", price: "30.00" },
      { imageSrc: "images/2/3.jpg", title: "Rava idly(1)", price: "60.00" },
      { imageSrc: "images/2/4.jpg", title: "Vada(1)", price: "40.00" },
      { imageSrc: "images/2/5.jpg", title: "Idly(2) Vada(1)", price: "85.00" },
      { imageSrc: "images/2/6.jpg", title: "Single idly vada", price: "60.00" },
      { imageSrc: "images/2/7.jpg", title: "Rice bath", price: "70.00" },
      { imageSrc: "images/2/8.jpg", title: "ChowChow Bath", price: "90.00" },
      { imageSrc: "images/2/9.jpg", title: "Kesaribath", price: "45.00" },
      { imageSrc: "images/2/10.jpg", title: "Kharabath", price: "45.00" },
      { imageSrc: "images/2/11.jpg", title: "Curd Vada (1)", price: "65.00" },
      { imageSrc: "images/2/12.jpg", title: "Poori", price: "90.00" },
      { imageSrc: "images/2/13.jpg", title: "Bonda Soup", price: "60.00" },
      { imageSrc: "images/2/14.jpg", title: "Bajji / Pakoda", price: "70.00" },
      { imageSrc: "images/2/15.jpg", title: "Pongal", price: "80.00" },
      { imageSrc: "images/2/16.jpg", title: "Bisi Bele Bath", price: "80.00" }
    ],
    DOSA: [
      { imageSrc: "images/3/1.jpg", title: "Plain Dosa", price: "75.00" },
      { imageSrc: "images/3/2.jpg", title: "Masala Dosa", price: "85.00" },
      { imageSrc: "images/3/3.jpg", title: "Onion Dosa", price: "100.00" },
      { imageSrc: "images/3/4.jpg", title: "Paper Plain Dosa", price: "110.00" },
      { imageSrc: "images/3/5.jpg", title: "Set Dosa (3 Pcs)", price: "90.00" },
      {
        imageSrc: "images/3/6.jpg",
        title: "Ghee Khali Dosa 2 Pcs",
        price: "110.00"
      },
      {
        imageSrc: "images/3/7.jpg",
        title: "Rava Onion Masala Dosa",
        price: "140.00",
      },
      { imageSrc: "images/3/8.jpg", title: "Rava Onion Dosa", price: "120.00" },
      {
        imageSrc: "images/3/9.jpg",
        title: "Rava Masala Dosa",
        price: "120.00",
      },
      { imageSrc: "images/3/10.jpg", title: "Rava Dosa", price: "110.00" },
      {
        imageSrc: "images/3/11.jpg",
        title: "Butter Masala Dosa",
        price: "100.00",
      },
      {
        imageSrc: "images/3/12.jpg",
        title: "Butter Plain Dosa",
        price: "90.00",
      },
      {
        imageSrc: "images/3/13.jpg",
        title: "Paper Butter Plain Dosa",
        price: "120.00",
      },
      {
        imageSrc: "images/3/14.jpg",
        title: "Paper Butter Masala Dosa",
        price: "130.00",
      },
      {
        imageSrc: "images/3/15.jpg",
        title: "Open Butter Masala Dosa",
        price: "130.00",
      },
      {
        imageSrc: "images/3/16.jpg",
        title: "Paper Masala Dosa",
        price: "130.00",
      },
      {
        imageSrc: "images/3/17.jpg",
        title: "Special Dosa of the day",
        price: "150.00",
      },
      { imageSrc: "images/3/18.jpg", title: "Neer Dosa", price: "100.00" },
      { imageSrc: "images/3/19.jpg", title: "Akki Roti", price: "100.00" },
      { imageSrc: "images/3/20.jpg", title: "Ragi Roti", price: "100.00" },
      {
        imageSrc: "images/3/21.jpg",
        title: "Cheese Masala Dosa",
        price: "150.00",
      },
      {
        imageSrc: "images/3/22.jpg",
        title: "Cheese Plain Dosa",
        price: "140.00",
      },
      {
        imageSrc: "images/3/23.jpg",
        title: "Paneer Butter Masala Dosa",
        price: "160.00",
      },
      {
        imageSrc: "images/3/24.jpg",
        title: "Mysore Masala Dosa",
        price: "150.00",
      },
      { imageSrc: "images/3/25.jpg", title: "Tomato omelette", price: "110.00" },
    ],
    MEALS: [
      {
        imageSrc: "images/1.jpg",
        title: (
          <>
            <span>
              Break Fast Combo{" "}
              <span style={{ color: "red" }}>7.00am to 12.00pm</span>
            </span>
            <li style={{ fontSize: "15px" }}>
              Kesari Bhath, Mini Masala Dosa, Cup Pongal, Mini Coffee Single,
              idly Mini vada
            </li>
          </>
        ),
        price: "160.00",
      },
      {
        imageSrc: "images/4/1.jpg",
        title: (
          <>
            <span>
              SOUTH INDIAN MEALS{" "}
              <span style={{ color: "red" }}>12.00pm to 4.00pm</span>
            </span>
            <li style={{ fontSize: "15px" }}>
              Welcome Juice, Payasa, Papad, Kosambari, Chutney, Palya,
              Poori(3pcs), Kootu,Verity Rice, White Rice, Pappu, Sambar, Rasam,
              Curd.
            </li>
          </>
        ),
        price: "160.00",
      },
      {
        imageSrc: "images/4/2.jpg",
        title: (
          <>
            <span>
              NORTH INDIAN MEALS{" "}
              <span style={{ color: "red" }}>
                12.00pm to 4.00pm, 7.00pm to 10.00pm{" "}
              </span>
            </span>
            <li style={{ fontSize: "15px" }}>
              Tomato Soup, Papad, Manchurian, Dry Sabjee, Paneer Butter Masala,
              Dhal Fry, Roti (2Pc), Veg Pulao, Curd Rice, Sweet, Ice Cream.
            </li>
          </>
        ),
        price: "260.00",
      },
      {
        imageSrc: "images/4/3.jpg",
        title: (
          <>
            <span>CHINESE COMBO MEALS </span>
            <li style={{ fontSize: "15px" }}>
              Soup, Papad, Manchurian, Fried Rice or Noodles.
            </li>
          </>
        ),
        price: "270.00",
      },
    ],
    SOUPS: [
      { imageSrc: "images/5/1.jpg", title: "Hot & Sour Soup", price: "130.00" },
      {
        imageSrc: "images/5/2.jpg",
        title: "Cream Of Mushroom Soup",
        price: "140.00",
      },
      {
        imageSrc: "images/5/3.jpg",
        title: "Cream Of Vegetable Soup",
        price: "130.00",
      },
      {
        imageSrc: "images/5/4.jpg",
        title: "Cream Of Tomato Soup",
        price: "100.00",
      },
      {
        imageSrc: "images/5/5.jpg",
        title: "Sweet Corn Veg Soup",
        price: "130.00",
      },
      { imageSrc: "images/5/6.jpg", title: "Manchow Soup", price: "140.00" },
      { imageSrc: "images/5/7.jpg", title: "Veg Clear Soup", price: "120.00" },
      { imageSrc: "images/5/8.jpg", title: "Veg Noodle Soup", price: "140.00" },
      {
        imageSrc: "images/5/9.jpg",
        title: "Cream Of Palak Soup",
        price: "130.00",
      },
    ],
    "SALADS & RAITHA": [
      { imageSrc: "images/6/1.jpg", title: "Tomato Salad", price: "110.00" },
      { imageSrc: "images/6/2.jpg", title: "Green Salad", price: "120.00" },
      { imageSrc: "images/6/3.jpg", title: "Cucumber Salad", price: "110.00" },
      { imageSrc: "images/6/4.jpg", title: "Onion Raitha", price: "110.00" },
      { imageSrc: "images/6/5.jpg", title: "Tomato Raitha", price: "110.00" },
      { imageSrc: "images/6/6.jpg", title: "Boondi Raitha", price: "110.00" },
      { imageSrc: "images/6/7.jpg", title: "Kashmiri Raitha", price: "120.00" },
      { imageSrc: "images/6/8.jpg", title: "Mixed Raitha", price: "110.00" },
      { imageSrc: "images/6/9.jpg", title: "Curd", price: "40.00" },
      {
        imageSrc: "images/6/10.jpg",
        title: "Pineapple Raitha",
        price: "120.00",
      },
    ],
    STARTERS: [
      { imageSrc: "images/7/1.jpg", title: "Masala Papad", price: "80.00" },
      {
        imageSrc: "images/7/2.jpg",
        title: "Papad Fry & Roast",
        price: "50.00",
      },
      { imageSrc: "images/7/3.jpg", title: "Finger Chips", price: "150.00" },
      { imageSrc: "images/7/4.jpg", title: "Gobi Manchurian", price: "180.00" },
      {
        imageSrc: "images/7/5.jpg",
        title: "Veg Ball Manchurian",
        price: "190.00",
      },
      {
        imageSrc: "images/7/6.jpg",
        title: "Baby Corn Manchurian",
        price: "190.00",
      },
      {
        imageSrc: "images/7/7.jpg",
        title: "Mushroom Manchurian",
        price: "200.00",
      },
      {
        imageSrc: "images/7/8.jpg",
        title: "Spinach Manchurian",
        price: "220.00",
      },
      {
        imageSrc: "images/7/9.jpg",
        title: "Paneer Manchurian",
        price: "250.00",
      },
      { imageSrc: "images/7/10.jpg", title: "Gobi 65", price: "190.00" },
      { imageSrc: "images/7/11.jpg", title: "Gobi Chilli", price: "190.00" },
      {
        imageSrc: "images/7/12.jpg",
        title: "Veg Ball Chilli",
        price: "190.00",
      },
      {
        imageSrc: "images/7/13.jpg",
        title: "Baby Corn Chilli",
        price: "190.00",
      },
      {
        imageSrc: "images/7/14.jpg",
        title: "Mushroom Chilli",
        price: "200.00",
      },
      { imageSrc: "images/7/15.jpg", title: "Paneer Chilli", price: "250.00" },
      {
        imageSrc: "images/7/16.jpg",
        title: "Paneer Pepper Dry",
        price: "250.00",
      },
      {
        imageSrc: "images/7/17.jpg",
        title: "Baby Corn Pepper Dry",
        price: "230.00",
      },
      {
        imageSrc: "images/7/18.jpg",
        title: "Mushroom Pepper Dry",
        price: "240.00",
      },
      { imageSrc: "images/7/19.jpg", title: "Crispy Veg", price: "200.00" },
      {
        imageSrc: "images/7/20.jpg",
        title: "Crispy Baby Corn",
        price: "220.00",
      },
      {
        imageSrc: "images/7/21.jpg",
        title: "Veg Spring Roll",
        price: "240.00",
      },
      {
        imageSrc: "images/7/22.jpg",
        title: "Veg Golden Kabab",
        price: "220.00",
      },
      {
        imageSrc: "images/7/23.jpg",
        title: "Veg Ball Schezwan",
        price: "220.00",
      },
      {
        imageSrc: "images/7/24.jpg",
        title: "Malaysia Spring Roll",
        price: "250.00",
      },
    ],
    "TANDOORI STARTERS": [
      {
        imageSrc: "images/8/1.jpg",
        title: "Tandoor Baby Aloo",
        price: "240.00",
      },
      { imageSrc: "images/8/2.jpg", title: "Paneer Tikka", price: "270.00" },
      {
        imageSrc: "images/8/3.jpg",
        title: "Paneer Tikka Lal Mirchi",
        price: "260.00",
      },
      {
        imageSrc: "images/8/4.jpg",
        title: "Paneer Peshwari Tikka",
        price: "280.00",
      },
      { imageSrc: "images/8/5.jpg", title: "Aloo Tikki", price: "220.00" },
      {
        imageSrc: "images/8/6.jpg",
        title: "Hariyali Paneer Tikka",
        price: "280.00",
      },
      { imageSrc: "images/8/7.jpg", title: "Mushroom Tikka", price: "250.00" },
      {
        imageSrc: "images/8/8.jpg",
        title: "Hara Bhara Kabab",
        price: "220.00",
      },
    ],
    "PANEER SPECIAL": [
      { imageSrc: "images/9/1.jpg", title: "Dragon Paneer", price: "270.00" },
      { imageSrc: "images/9/2.jpg", title: "Mint Paneer", price: "260.00" },
      {
        imageSrc: "images/9/3.jpg",
        title: "Paneer Cheese Ball (6pcs)",
        price: "260.00",
      },
      { imageSrc: "images/9/4.jpg", title: "Paneer 65", price: "260.00" },
      { imageSrc: "images/9/5.jpg", title: "Kaju Paneer", price: "290.00" },
      {
        imageSrc: "images/9/6.jpg",
        title: "Thai Paneer Green",
        price: "270.00",
      },
      {
        imageSrc: "images/9/7.jpg",
        title: "Paneer Sathae (6pcs)",
        price: "270.00",
      },
    ],
    "BABY CORN-SPECIALS": [
      {
        imageSrc: "images/10/1.jpg",
        title: "Baby Corn Golden Fry",
        price: "240.00",
      },
      {
        imageSrc: "images/10/2.jpg",
        title: "Chilli Honey Baby Corn",
        price: "240.00",
      },
      {
        imageSrc: "images/10/3.jpg",
        title: "Babycorn Sathae (8pcs)",
        price: "250.00",
      },
      {
        imageSrc: "images/10/4.jpg",
        title: "Dragon Babycorn",
        price: "250.00",
      },
      {
        imageSrc: "images/10/5.jpg",
        title: "Thai Babycorn Green",
        price: "240.00",
      },
      {
        imageSrc: "images/10/6.jpg",
        title: "Thai Baby Corn Red",
        price: "240.00",
      },
    ],
    "MUSHROOM SPECIALS": [
      {
        imageSrc: "images/11/1.jpg",
        title: "Dragon Mushroom",
        price: "250.00",
      },
      {
        imageSrc: "images/11/2.jpg",
        title: "Stepped Mushroom",
        price: "250.00",
      },
      { imageSrc: "images/11/3.jpg", title: "Thai Mushroom", price: "250.00" },
    ],
    "GOBI SPECIALS ": [
      { imageSrc: "images/12/1.jpg", title: "Dragon Gobi", price: "220.00" },
      {
        imageSrc: "images/12/2.jpg",
        title: "Thai Gobi Green",
        price: "230.00",
      },
      { imageSrc: "images/12/3.jpg", title: "Thai Gobi Red", price: "230.00" },
      {
        imageSrc: "images/12/4.jpg",
        title: "Cheese Ball(6pcs)",
        price: "240.00",
      },
      {
        imageSrc: "images/12/5.jpg",
        title: "Coriander Roll(6pcs)",
        price: "230.00",
      },
      {
        imageSrc: "images/12/6.jpg",
        title: "Dragon Roll(6pcs)",
        price: "230.00",
      },
      {
        imageSrc: "images/12/7.jpg",
        title: "Veg Finger With Peanut Sauce",
        price: "230.00",
      },
      {
        imageSrc: "images/12/8.jpg",
        title: "Peanut Cheese Ball(6pcs)",
        price: "230.00",
      },
      {
        imageSrc: "images/12/9.jpg",
        title: "Andhra Gobi Chilli",
        price: "230.00",
      },
    ],
    "TANDOORI BREADS ": [
      { imageSrc: "images/13/1.jpg", title: "Roti", price: "35.00" },
      { imageSrc: "images/13/2.jpg", title: "Butter Roti", price: "45.00" },
      { imageSrc: "images/13/3.jpg", title: "Pudina Roti", price: "60.00" },
      { imageSrc: "images/13/4.jpg", title: "Methi Roti", price: "60.00" },
      { imageSrc: "images/13/5.jpg", title: "Kulcha", price: "60.00" },
      { imageSrc: "images/13/6.jpg", title: "Butter Kulcha", price: "70.00" },
      { imageSrc: "images/13/7.jpg", title: "Masala Kulcha", price: "70.00" },
      { imageSrc: "images/13/8.jpg", title: "Onion kulcha", price: "80.00" },
      { imageSrc: "images/13/9.jpg", title: "Stuffed kulcha", price: "80.00" },
      { imageSrc: "images/13/10.jpg", title: "Naan", price: "50.00" },
      { imageSrc: "images/13/11.jpg", title: "Butter Naan", price: "60.00" },
      { imageSrc: "images/13/12.jpg", title: "Garlic Naan", price: "80.00" },
      {
        imageSrc: "images/13/13.jpg",
        title: "Paneer Cheese Naan",
        price: "100.00",
      },
      { imageSrc: "images/13/14.jpg", title: "Kashmiri Naan", price: "130.00" },
      { imageSrc: "images/13/15.jpg", title: "Muglai Naan", price: "120.00" },
      {
        imageSrc: "images/13/16.jpg",
        title: "Roti Basket(2 Roti,2kulcha,1Naan)",
        price: "280.00",
      },
      { imageSrc: "images/13/17.jpg", title: "Plain Paratha", price: "70.00" },
      { imageSrc: "images/13/18.jpg", title: "Pudina Paratha", price: "80.00" },
      { imageSrc: "images/13/19.jpg", title: "Methi Paratha", price: "80.00" },
      {
        imageSrc: "images/13/20.jpg",
        title: "Stuffed Paratha",
        price: "90.00",
      },
      {
        imageSrc: "images/13/21.jpg",
        title: "Aloo Paratha + Curd + Pickle",
        price: "110.00",
      },
      { imageSrc: "images/13/22.jpg", title: "Ajwain Paratha", price: "90.00" },
      {
        imageSrc: "images/13/23.jpg",
        title: "Channa Bathura",
        price: "170.00",
      },
      { imageSrc: "images/13/24.jpg", title: "Extra Bathura", price: "80.00" },
    ],
    "VEG CURRIES-DALS": [
      { imageSrc: "images/14/1.jpg", title: "Dal Fry", price: "170.00" },
      { imageSrc: "images/14/2.jpg", title: "Dal Tadka", price: "185.00" },
      { imageSrc: "images/14/3.jpg", title: "Dal Palak", price: "190.00" },
      { imageSrc: "images/14/4.jpg", title: "Dal Punjabi", price: "190.00" },
      { imageSrc: "images/14/5.jpg", title: "Dal Makhani", price: "190.00" },
      { imageSrc: "images/14/6.jpg", title: "Rajma Dal", price: "200.00" },
      { imageSrc: "images/14/7.jpg", title: "Dal Kabila", price: "200.00" },
      { imageSrc: "images/14/8.jpg", title: "Dal Kolhapuri", price: "200.00" },
    ],
    "VEG CURRY-MASALA SPECIAL": [
      { imageSrc: "images/15/1.jpg", title: "Channa Masala", price: "190.00" },
      { imageSrc: "images/15/2.jpg", title: "Gobi Masala", price: "180.00" },
      {
        imageSrc: "images/15/3.jpg",
        title: "Green Peas Masala",
        price: "190.00",
      },
      {
        imageSrc: "images/15/4.jpg",
        title: "Capsicum Masala",
        price: "190.00",
      },
      {
        imageSrc: "images/15/5.jpg",
        title: "Veg Pepper Masala",
        price: "230.00",
      },
      {
        imageSrc: "images/15/6.jpg",
        title: "Baby Corn Matar Masala",
        price: "230.00",
      },
      {
        imageSrc: "images/15/7.jpg",
        title: "Paneer Tikka Masala",
        price: "280.00",
      },
      {
        imageSrc: "images/15/8.jpg",
        title: "Paneer Butter Masala",
        price: "240.00",
      },
      {
        imageSrc: "images/15/9.jpg",
        title: "Paneer Tikka Butter Masala",
        price: "280.00",
      },
      {
        imageSrc: "images/15/10.jpg",
        title: "Baby Corn Paneer Masala",
        price: "240.00",
      },
      {
        imageSrc: "images/15/11.jpg",
        title: "Mushroom Masala",
        price: "220.00",
      },
      { imageSrc: "images/15/12.jpg", title: "Kaju Masala", price: "300.00" },
      {
        imageSrc: "images/15/13.jpg",
        title: "Aaradhya Special Curry",
        price: "300.00",
      },
      {
        imageSrc: "images/15/14.jpg",
        title: "Mushroom Tikka Masala",
        price: "250.00",
      },
      {
        imageSrc: "images/15/15.jpg",
        title: "Vegtable Jal Frezi",
        price: "250.00",
      },
      {
        imageSrc: "images/15/16.jpg",
        title: "Veg Shahi Paneer",
        price: "260.00",
      },
      { imageSrc: "images/15/17.jpg", title: "Veg Patiyala", price: "250.00" },
      {
        imageSrc: "images/15/18.jpg",
        title: "Boiled Vegetable",
        price: "230.00",
      },
      {
        imageSrc: "images/15/19.jpg",
        title: "Mixed Veg Curry",
        price: "230.00",
      },
      { imageSrc: "images/15/20.jpg", title: "Kashmiri Gobi", price: "240.00" },
      {
        imageSrc: "images/15/21.jpg",
        title: "Veg Makhanwala",
        price: "240.00",
      },
      { imageSrc: "images/15/22.jpg", title: "Veg Do-Pyaza", price: "240.00" },
      { imageSrc: "images/15/23.jpg", title: "Paneer Kurma", price: "250.00" },
      { imageSrc: "images/15/24.jpg", title: "Paneer Bhurji", price: "270.00" },
      { imageSrc: "images/15/25.jpg", title: "Paneer Pasand", price: "250.00" },
      { imageSrc: "images/15/26.jpg", title: "Shahee Paneer", price: "240.00" },
      { imageSrc: "images/15/27.jpg", title: "Matar Paneer", price: "230.00" },
      { imageSrc: "images/15/28.jpg", title: "Channa Paneer", price: "220.00" },
      { imageSrc: "images/15/29.jpg", title: "Malai Matar", price: "210.00" },
      {
        imageSrc: "images/15/30.jpg",
        title: "Malai Methi Matar",
        price: "230.00",
      },
    ],
    "VEG CURRY-ALOO SPECIAL": [
      { imageSrc: "images/16/1.jpg", title: "Aloo Channa", price: "200.00" },
      { imageSrc: "images/16/2.jpg", title: "Aloo Palak", price: "200.00" },
      { imageSrc: "images/16/3.jpg", title: "Aloo Gobi", price: "200.00" },
      { imageSrc: "images/16/4.jpg", title: "Aloo Matar", price: "200.00" },
      { imageSrc: "images/16/5.jpg", title: "Aloo Rajma", price: "200.00" },
      { imageSrc: "images/16/6.jpg", title: "Aloo Jeera Dry", price: "210.00" },
      {
        imageSrc: "images/16/7.jpg",
        title: "Dum Aloo Kashmiri",
        price: "230.00",
      },
      { imageSrc: "images/16/8.jpg", title: "Aloo Methi Dry", price: "200.00" },
      { imageSrc: "images/16/9.jpg", title: "Aloo Fry", price: "200.00" },
      {
        imageSrc: "images/16/10.jpg",
        title: "Dum Aloo Hydrabadi",
        price: "220.00",
      },
      {
        imageSrc: "images/16/11.jpg",
        title: "Stuffed Capsicum",
        price: "230.00",
      },
      {
        imageSrc: "images/16/12.jpg",
        title: "Stuffed Tomato",
        price: "230.00",
      },
      {
        imageSrc: "images/16/13.jpg",
        title: "Alu Tikka Masala",
        price: "230.00",
      },
    ],
    "VEG CURRY PALAK SPECIAL": [
      { imageSrc: "images/17/1.jpg", title: "Palak Gobi", price: "190.00" },
      { imageSrc: "images/17/2.jpg", title: "Plain palak", price: "180.00" },
      { imageSrc: "images/17/3.jpg", title: "Peas Palak", price: "190.00" },
      { imageSrc: "images/17/4.jpg", title: "Palak Paneer", price: "230.00" },
      { imageSrc: "images/17/5.jpg", title: "Corn Palak", price: "230.00" },
      { imageSrc: "images/17/6.jpg", title: "Dal Palak", price: "200.00" },
    ],
    "VEG CURRY-KOFTA": [
      { imageSrc: "images/18/1.jpg", title: "Veg Kofta", price: "230.00" },
      {
        imageSrc: "images/18/2.jpg",
        title: "Veg Malai Kofta",
        price: "240.00",
      },
      { imageSrc: "images/18/3.jpg", title: "Nargis Kofta", price: "230.00" },
      { imageSrc: "images/18/4.jpg", title: "Paneerkofta", price: "250.00" },
      { imageSrc: "images/18/5.jpg", title: "Dil Bhar Kofta", price: "240.00" },
    ],
    "VEG CURRY-MATAR SPECIAL": [
      { imageSrc: "images/19/1.jpg", title: "Malai Matar", price: "200.00" },
      { imageSrc: "images/19/2.jpg", title: "Koya Matar", price: "200.00" },
      {
        imageSrc: "images/19/3.jpg",
        title: "Malai Methi Matar",
        price: "210.00",
      },
      {
        imageSrc: "images/19/4.jpg",
        title: "Mushroom Matar",
        price: "220.00",
      },
      { imageSrc: "images/19/5.jpg", title: "Kajoo Matar", price: "270.00" },
    ],
    "VEG CURRY-SPECIAL GREENS": [
      { imageSrc: "images/20/1.jpg", title: "Veg Makhanwala", price: "240.00" },
      { imageSrc: "images/20/2.jpg", title: "Veg Do–pyaza", price: "230.00" },
      { imageSrc: "images/20/3.jpg", title: "Veg Hydrabadi", price: "240.00" },
      { imageSrc: "images/20/4.jpg", title: "Veg Kurma", price: "230.00" },
      { imageSrc: "images/20/5.jpg", title: "Veg Kolhapuri", price: "240.00" },
      {
        imageSrc: "images/20/6.jpg",
        title: "Veg Shahi Kurma",
        price: "240.00",
      },
      {
        imageSrc: "images/20/7.jpg",
        title: "Navarathna Kurma",
        price: "240.00",
      },
    ],
    "KADAI SPECIAL": [
      { imageSrc: "images/21/1.jpg", title: "Kadai Mushroom", price: "260.00" },
      {
        imageSrc: "images/21/2.jpg",
        title: "kadai Gobi Matar",
        price: "250.00",
      },
      {
        imageSrc: "images/21/3.jpg",
        title: "Kadai Baby Corn",
        price: "240.00",
      },
      { imageSrc: "images/21/4.jpg", title: "Kadai mix veg", price: "240.00" },
      { imageSrc: "images/21/5.jpg", title: "kadai paneer", price: "270.00" },
    ],
    "INDIAN RICE SPECIAL": [
      { imageSrc: "images/22/1.jpg", title: "Curd Rice", price: "100.00" },
      {
        imageSrc: "images/22/2.jpg",
        title: "Basmati Rice Plain",
        price: "120.00",
      },
      { imageSrc: "images/22/3.jpg", title: "Veg Pulao", price: "190.00" },
      { imageSrc: "images/22/4.jpg", title: "Kashmiri Pulao", price: "220.00" },
      {
        imageSrc: "images/22/5.jpg",
        title: "Veg Handi Pulao",
        price: "220.00",
      },
      { imageSrc: "images/22/6.jpg", title: "Peas Pulao", price: "190.00" },
      { imageSrc: "images/22/7.jpg", title: "Veg Biryani", price: "200.00" },
      {
        imageSrc: "images/22/8.jpg",
        title: "Veg Handi Biryani",
        price: "220.00",
      },
      {
        imageSrc: "images/22/9.jpg",
        title: "Veg Hyderabadi Biryani",
        price: "230.00",
      },
      {
        imageSrc: "images/22/10.jpg",
        title: "Paneer Biryani",
        price: "240.00",
      },
      {
        imageSrc: "images/22/11.jpg",
        title: "Mughlai Biryani",
        price: "220.00",
      },
      { imageSrc: "images/22/12.jpg", title: "Dal Khichdi", price: "200.00" },
      { imageSrc: "images/22/13.jpg", title: "Palak Khichdi", price: "190.00" },
      {
        imageSrc: "images/22/14.jpg",
        title: "Paneer Tikka Biriyani",
        price: "270.00",
      },
      { imageSrc: "images/22/15.jpg", title: "Nuts Pulao", price: "260.00" },
      { imageSrc: "images/22/16.jpg", title: "Palak Rice", price: "190.00" },
      {
        imageSrc: "images/22/17.jpg",
        title: "Aaradya Grand special Biriyan",
        price: "280.00",
      },
      {
        imageSrc: "images/22/18.jpg",
        title: "Mushroom Biriyani",
        price: "250.00",
      },
    ],
    "CHINESE RICE SPECIAL": [
      {
        imageSrc: "images/23/1.jpg",
        title: "Jeera Fried Rice",
        price: "190.00",
      },
      { imageSrc: "images/23/2.jpg", title: "Ghee Rice", price: "200.00" },
      { imageSrc: "images/23/3.jpg", title: "Veg Fried Rice", price: "180.00" },
      {
        imageSrc: "images/23/4.jpg",
        title: "Kaju Fried Rice",
        price: "240.00",
      },
      {
        imageSrc: "images/23/5.jpg",
        title: "Veg Schezwan Rice",
        price: "200.00",
      },
      {
        imageSrc: "images/23/6.jpg",
        title: "Mushroom Fried Rice",
        price: "200.00",
      },
      {
        imageSrc: "images/23/7.jpg",
        title: "Singapore Fried Rice",
        price: "200.00",
      },
      {
        imageSrc: "images/23/8.jpg",
        title: "Paneer Fried Rice",
        price: "220.00",
      },
      {
        imageSrc: "images/23/9.jpg",
        title: "Veg Triple Fried Rice",
        price: "220.00",
      },
      { imageSrc: "images/23/10.jpg", title: "Jeera Rice", price: "190.00" },
      {
        imageSrc: "images/23/11.jpg",
        title: "Manchow Fried Rice",
        price: "200.00",
      },],

      "NOODLES":[
        { imageSrc: "images/24/1.jpg", title: "Veg Noodles", price: "180.00" },
        {
          imageSrc: "images/24/2.jpg",
          title: "Veg Hakka Noodles",
          price: "200.00",
        },
        {
          imageSrc: "images/24/3.jpg",
          title: "Veg Schezwan Noodles",
          price: "200.00",
        },
        {
          imageSrc: "images/24/4.jpg",
          title: "Veg Chow Men Noodles",
          price: "210.00",
        },
        {
          imageSrc: "images/24/5.jpg",
          title: "American Chopsy",
          price: "230.00",
        },
        {
          imageSrc: "images/24/6.jpg",
          title: "Chinese Chopsy",
          price: "240.00",
        },
        {
          imageSrc: "images/24/7.jpg",
          title: "Mushroom Noodles",
          price: "220.00",
        },
        {
          imageSrc: "images/24/8.jpg",
          title: "Singapur Noodles",
          price: "210.00",
        },
        {
          imageSrc: "images/24/9.jpg",
          title: "Paneer Noodles",
          price: "270.00",
        },
        {
          imageSrc: "images/24/10.jpg",
          title: "Baby Corn Noodles",
          price: "200.00",
        },
        { imageSrc: "images/24/11.jpg", title: "Gobi Noodles", price: "190.00" }
      ],
    "FRESH FRUIT JUICE": [
      { imageSrc: "images/25/1.jpg", title: "Mango", price: "80.00" },
      { imageSrc: "images/25/2.jpg", title: "Grape", price: "80.00" },
      { imageSrc: "images/25/3.jpg", title: "Pineapple", price: "80.00" },
      { imageSrc: "images/25/4.jpg", title: "Orange", price: "80.00" },
      { imageSrc: "images/25/5.jpg", title: "Mosambi", price: "80.00" },
      { imageSrc: "images/25/6.jpg", title: "Fresh Lime", price: "50.00" },
      { imageSrc: "images/25/7.jpg", title: "Watermelon", price: "70.00" },
      { imageSrc: "images/25/8.jpg", title: "Pudina Lime", price: "60.00" },
      { imageSrc: "images/25/9.jpg", title: "Fresh Lime Soda", price: "70.00" },
      {
        imageSrc: "images/25/10.jpg",
        title: "Cold Badam Milk",
        price: "80.00",
      },
      { imageSrc: "images/25/11.jpg", title: "Musk Melon", price: "70.00" },
      {
        imageSrc: "images/25/12.jpg",
        title: "Mineral Water 1ltr",
        price: "MRP",
      },
    ],
    "MILK SHAKES": [
      { imageSrc: "images/26/1.jpg", title: "Apple", price: "120.00 å" },
      { imageSrc: "images/26/2.jpg", title: "Chickoo", price: "120.00" },
      { imageSrc: "images/26/3.jpg", title: "Banana", price: "110.00" },
      { imageSrc: "images/26/4.jpg", title: "Mango", price: "120.00" },
      { imageSrc: "images/26/5.jpg", title: "Musk Melon", price: "100.00" },
      { imageSrc: "images/26/6.jpg", title: "Pomegranate", price: "130.00" },
      { imageSrc: "images/26/7.jpg", title: "Butter Fruits", price: "140.00" },
      { imageSrc: "images/26/8.jpg", title: "Vanilla", price: "120.00" },
      { imageSrc: "images/26/9.jpg", title: "Strawberry", price: "120.00" },
      { imageSrc: "images/26/10.jpg", title: "Butter Scotch", price: "130.00" },
      // { imageSrc: "images/26/11.jpg", title: "Mango", price: "130.00" },
      { imageSrc: "images/26/12.jpg", title: "Chocolate", price: "120.00" },
      { imageSrc: "images/26/13.jpg", title: "Black Current", price: "130.00" },
      { imageSrc: "images/26/14.jpg", title: "Pista", price: "130.00" },
      { imageSrc: "images/26/15.jpg", title: "Dry Fruits", price: "170.00" },
    ],
    "ICE CREAMS ": [
      { imageSrc: "images/27/1.jpg", title: "Vanilla", price: "70.00" },
      {
        imageSrc: "images/27/2.jpg",
        title: "Vanilla Choco Chips",
        price: "85.00",
      },
      { imageSrc: "images/27/3.jpg", title: "Strawbery", price: "80.00" },
      { imageSrc: "images/27/4.jpg", title: "Pista", price: "85.00" },
      { imageSrc: "images/27/5.jpg", title: "Kesari Pista", price: "90.00" },
      { imageSrc: "images/27/6.jpg", title: "Butter Scotch", price: "90.00" },
      { imageSrc: "images/27/7.jpg", title: "Mango", price: "80.00" },
      { imageSrc: "images/27/8.jpg", title: "Choclate", price: "90.00" },
      { imageSrc: "images/27/9.jpg", title: "Black Current", price: "90.00" },
      { imageSrc: "images/27/10.jpg", title: "Dry Fruits", price: "95.00" },
      { imageSrc: "images/27/11.jpg", title: "Kaju Katli", price: "110.00" },
      {
        imageSrc: "images/27/12.jpg",
        title: "Dry Friuts Gulkand Kulfi",
        price: "110.00",
      },
      { imageSrc: "images/27/13.jpg", title: "Lichi", price: "110.00" },
    ],
    "SPECIAL ICE CREAMS": [
      { imageSrc: "images/28/1.jpg", title: "Nut Sunday", price: "180.00" },
      {
        imageSrc: "images/28/2.jpg",
        title: "Honey Moon Special",
        price: "180.00",
      },
      {
        imageSrc: "images/28/3.jpg",
        title: "Titanic Special",
        price: "180.00",
      },
      { imageSrc: "images/28/4.jpg", title: "Raja Rani", price: "180.00" },
      {
        imageSrc: "images/28/5.jpg",
        title: "Tuty Fruity Special",
        price: "150.00",
      },
      { imageSrc: "images/28/6.jpg", title: "Gudbud", price: "180.00" },
      {
        imageSrc: "images/28/7.jpg",
        title: "Vanilla Nuts Sunday",
        price: "180.00",
      },
      {
        imageSrc: "images/28/8.jpg",
        title: "Choclate Nuts Sunday",
        price: "180.00",
      },
      { imageSrc: "images/28/9.jpg", title: "Fruits Salad", price: "140.00" },
      {
        imageSrc: "images/28/10.jpg",
        title: "Fruits Salad With Ice Cream",
        price: "180.00",
      },
      {
        imageSrc: "images/28/11.jpg",
        title: "Aaradya Grand special Ice Cream",
        price: "220.00",
      },
      { imageSrc: "images/28/12.jpg", title: "Falooda", price: "180.00" },
      {
        imageSrc: "images/28/13.jpg",
        title: "Special Falooda",
        price: "190.00",
      },
      {
        imageSrc: "images/28/14.jpg",
        title: "Kesari Falooda",
        price: "190.00",
      },
      { imageSrc: "images/28/15.jpg", title: "Royal Falooda", price: "190.00" },
      { imageSrc: "images/28/16.jpg", title: "Kulfi Falooda", price: "190.00" },
      {
        imageSrc: "images/28/17.jpg",
        title: "Hot Choclate Pudge",
        price: "170.00",
      },
      { imageSrc: "images/28/18.jpg", title: "Joker Man", price: "180.00" },
      { imageSrc: "images/28/19.jpg", title: "Double Sunday", price: "180.00" },
      { imageSrc: "images/28/20.jpg", title: "Triple Sunday", price: "200.00" },
      { imageSrc: "images/28/21.jpg", title: "Banana Splite", price: "180.00" },
      {
        imageSrc: "images/28/22.jpg",
        title: "Lovers Special",
        price: "180.00",
      },
      {
        imageSrc: "images/28/23.jpg",
        title: "Jelly With Ice Cream",
        price: "160.00",
      },
    ],
    "SPECIAL VEG SANDWICH": [
      { imageSrc: "images/29/1.jpg", title: "Veg Sandwich", price: "80.00" },
      {
        imageSrc: "images/29/2.jpg",
        title: "Veg Grill Sandwich",
        price: "120.00",
      },
      {
        imageSrc: "images/29/3.jpg",
        title: "Veg Cheese Grill Sandwich",
        price: "130.00",
      },
      {
        imageSrc: "images/29/4.jpg",
        title: "Plain Cheese Sandwich",
        price: "110.00",
      },
      {
        imageSrc: "images/29/5.jpg",
        title: "Veg Mushroom Sandwich",
        price: "140.00",
      },
      {
        imageSrc: "images/29/6.jpg",
        title: "Veg Paneer Sandwich",
        price: "160.00",
      },
      {
        imageSrc: "images/29/7.jpg",
        title: "Special Sand Wich",
        price: "160.00",
      },
      {
        imageSrc: "images/29/8.jpg",
        title: "Sweet Corn Sandwich",
        price: "130.00",
      },
      {
        imageSrc: "images/29/9.jpg",
        title: "Bread Butter Jam",
        price: "90.00",
      },
      {
        imageSrc: "images/29/10.jpg",
        title: "Bread Butter Toast",
        price: "80.00",
      },
      {
        imageSrc: "images/29/11.jpg",
        title: "Fruits Sandwich",
        price: "130.00",
      },
    ],
    LASSI: [
      {
        imageSrc: "images/30/1.jpg",
        title: "Sweet / Salt Lassi",
        price: "90.00",
      },
      { imageSrc: "images/30/2.jpg", title: "Special Lassi", price: "100.00" },
      { imageSrc: "images/30/3.jpg", title: "Mango Lassi", price: "120.00" },
      {
        imageSrc: "images/30/4.jpg",
        title: "Strawberry Lassi",
        price: "120.00",
      },
      { imageSrc: "images/30/5.jpg", title: "Butter Milk", price: "50.00" },
    ],
    CHATS: [
      { imageSrc: "images/31/1.jpg", title: "Pav Bhaji", price: "100.00" },
      {
        imageSrc: "images/31/2.jpg",
        title: "Extra Pav And Butter",
        price: "40.00",
      },
      { imageSrc: "images/31/3.jpg", title: "Alu Chats", price: "80.00" },
      { imageSrc: "images/31/4.jpg", title: "Alu Puri", price: "80.00" },
      { imageSrc: "images/31/5.jpg", title: "Papdi Chat", price: "80.00" },
      { imageSrc: "images/31/6.jpg", title: "Special Bhel", price: "70.00" },
      { imageSrc: "images/31/7.jpg", title: "Pani Puri", price: "70.00" },
      {
        imageSrc: "images/31/8.jpg",
        title: "Special Sev Puri",
        price: "85.00",
      },
      {
        imageSrc: "images/31/9.jpg",
        title: "Vegetable Samosa (1)",
        price: "40.00",
      },
      {
        imageSrc: "images/31/10.jpg",
        title: "Vegetable Cutlet (1)",
        price: "40.00",
      },
      {
        imageSrc: "images/31/11.jpg",
        title: "Sev Batata Puri",
        price: "85.00",
      },
      {
        imageSrc: "images/31/12.jpg",
        title: "Sev Dahi Batatapuri",
        price: "90.00",
      },
      { imageSrc: "images/31/13.jpg", title: "Pakodi Bhel", price: "80.00" },
      { imageSrc: "images/31/14.jpg", title: "Masala Puri", price: "70.00" },
      { imageSrc: "images/31/15.jpg", title: "Dahi Puri", price: "90.00" },
      { imageSrc: "images/31/16.jpg", title: "Samosa Masala", price: "80.00" },
      { imageSrc: "images/31/17.jpg", title: "Cuttlet Masala", price: "80.00" },
      { imageSrc: "images/31/18.jpg", title: "Nippat Masala", price: "90.00" },
      { imageSrc: "images/31/19.jpg", title: "Ragda Pattice", price: "90.00" },
    ],
    "SWEET- REGULAR": [
      {
        imageSrc: "images/32/1.jpg",
        title: "Hot Gulab Jamoon",
        price: "50.00",
      },
      { imageSrc: "images/32/2.jpg", title: "Carrot Halwa", price: "65.00" },
      {
        imageSrc: "images/32/3.jpg",
        title: "Jamoon With Ice Cream",
        price: "110.00",
      },
      {
        imageSrc: "images/32/4.jpg",
        title: "Carrot Halwa With Ice Cream",
        price: "110.00",
      },
    ],
    EXTRA: [
      { imageSrc: "images/33/1.jpg", title: "Extra Bowl Curd", price: "50.00" },
      { imageSrc: "images/33/2.jpg", title: "Extra Charge", price: "10.00" },
      {
        imageSrc: "images/33/3.jpg",
        title: "Extra Butter/ghee",
        price: "20.00",
      },
      { imageSrc: "images/33/4.jpg", title: "Extra Cup Curd", price: "20.00" },
      { imageSrc: "images/33/5.jpg", title: "Extra Curry", price: "25.00" },
      {
        imageSrc: "images/33/6.jpg",
        title: "Extra Meals Puri",
        price: "40.00",
      },
      { imageSrc: "images/33/7.jpg", title: "Extra Papad", price: "15.00" },
      { imageSrc: "images/33/8.jpg", title: "Extra Rice", price: "40.00" },
      {
        imageSrc: "images/33/9.jpg",
        title: "Extra Meals Sweet",
        price: "25.00",
      },
      { imageSrc: "images/33/10.jpg", title: "Extra Palya", price: "20.00" },
    ]
},
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs)
  const [activeTab, setActiveTab] = useState(tabsKeys[0])

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <div>
            <Header>{heading}</Header>
          </div>
          <br />
        </HeaderRow>
        <div>
          {/* <span className="text-size-5">Select </span> */}
          {/* <select onChange={(e) => setActiveTab(e.target.value)}>
           */}
          <TabsControl
            style={{
              overflowY: "scroll",
              width: "100%",
              maxWidth:"900px",
              height: "200px",
              display: "flex",
              margin:"auto",
              marginTop:"20px"
              
            }}
          >
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                className={tabName === activeTab ? "acti" : ""}
                key={index}
                active={activeTab === tabName}
                value={tabName}
                onClick={() => setActiveTab(tabName)}
                style={{ display: "inline-block" }}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </div>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className='group'
                  href={card.url}
                  initial='rest'
                  whileHover='hover'
                  animate='rest'
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <Skeleton
                      height='110%'
                      containerClassName='avatar-skeleton'
                      style={{ zIndex: "-1" }}
                    />
                    {/* <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton> */}
                    {/* </CardHoverOverlay> */}
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    {/* <CardContent>{card.content}</CardContent> */}
                    <CardPrice>Rs.{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  )
}

// /* This function is only there for demo purposes. It populates placeholder cards */
// const getRandomCards = () => {
//   const cards = [
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Chicken Chilled",
//       content: "Chicken Main Course",
//       price: "$5.99",
//       rating: "5.0",
//       reviews: "87",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Samsa Beef",
//       content: "Fried Mexican Beef",
//       price: "$3.99",
//       rating: "4.5",
//       reviews: "34",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Carnet Nachos",
//       content: "Chilli Crispy Nachos",
//       price: "$3.99",
//       rating: "3.9",
//       reviews: "26",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Guacamole Mex",
//       content: "Mexican Chilli",
//       price: "$3.99",
//       rating: "4.2",
//       reviews: "95",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Chillie Cake",
//       content: "Deepfried Chicken",
//       price: "$2.99",
//       rating: "5.0",
//       reviews: "61",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Nelli",
//       content: "Hamburger & Fries",
//       price: "$7.99",
//       rating: "4.9",
//       reviews: "89",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Jalapeno Poppers",
//       content: "Crispy Soyabeans",
//       price: "$8.99",
//       rating: "4.6",
//       reviews: "12",
//       url: "#"
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Cajun Chicken",
//       content: "Roasted Chicken & Egg",
//       price: "$7.99",
//       rating: "4.2",
//       reviews: "19",
//       url: "#"
//     }
//   ];

//   // Shuffle array
//   return cards.sort(() => Math.random() - 0.5);
// };
