import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            Delicious and Pure Veg{" "}
            <HighlightedText>Meals Near You.</HighlightedText>
          </>
        }
        description='A royal ambience that goes down well with the lavish spread of pure veg food.'
        imageSrc='images/main/2.jpg'
        imageCss={imageCss}
        imageDecoratorBlob={true}
        // primaryButtonText="Order Now"
        // watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        subheading={<Subheading>Established Since 2017</Subheading>}
        heading={
          <>
            We've been serving for
            <wbr /> <HighlightedText>over 3 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            Aaradhya Grand specializes in delicious food featuring fresh
            ingredients and masterful preparation by the Aaradhya Grand's
            culinary team.
            <br />
            <br />
            Aaradhya Grand’s lively, casual yet upscale atmosphere makes it
            perfect for dining with friends, family, clients and business
            associates.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText={false}
        imageSrc={
          Math.floor(Math.random() * 2) + 1 === 1
            ? "images/main.jpg"
            : "images/main2.jpg"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>menu.</HighlightedText>
          </>
        }
        style={{display:"flex",justifyContent:"center",alignItems:"center",alignSelf:"center"}}
      />
      <Features
        heading={
          <>
           Our <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Party Hall",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "/partyhall",
          },
          // {
          //   imageSrc: chefIconImageSrc,
          //   title: "Professional Chefs",
          //   description: "Lorem ipsum donor amet siti ceali placeholder text",
          //   url: "https://timerse.com",
          // },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Catering",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "/catering",
          },
        ]}
        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={
          <>
            Why <HighlightedText>Choose Us ?</HighlightedText>
          </>
        }
        
        primaryButtonText={false}
        primaryButtonUrl='https://order.now.com'
        imageInsideDiv={false}
        imageSrc='https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80'
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      /> */}
      {/* <Testimonial
        subheading=''
        heading={
          <>
            Customers <HighlightedText>Love Us.</HighlightedText>
          </>
        }
      /> */}

      {/* <Footer /> */}
    </AnimationRevealPage>
  )
}
