import React ,{useRef} from 'react'
import { Rerousel } from 'rerousel';
import styled from 'styled-components';

function Carousel() {
  const customerLogo = useRef(null);

  const Img = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    border-radius:20px;
    width: 90em;
    @media(max-width: 1150px) {
        width: 100%
    }
  `;

  return (
    
      <Rerousel interval={3000} itemRef={customerLogo}>
                {[2,5,6,7,9,10,11,12,17,20,22,23,27].map((c,index) => {
                   return <Img key={index} src={`images/main/${c}.jpg`} ref={customerLogo} />;
                })}
       </Rerousel>
  );
}

export default Carousel
