import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { Link } from "react-router-dom";

const Container = tw(ContainerBase)`bg-yellow-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-16`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Lin = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const P = tw.p`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>
              <div>
                <span
                  className='heading'
                  style={{ color: "#f48444", marginRight: "10px" }}
                >
                  Aaradhya
                </span>
                <span
                  className='heading'
                  style={{ color: "#231f20", marginRight: "10px" }}
                >
                  Grand
                </span>
                <br />
                <h1 className='heading2'>VEg Restaurant</h1>
              </div>
            </LogoText>
          </LogoContainer>
          <P>Near Nandini Palace, NH-75, Kolar-563101</P>
          <LinksContainer>
            <Link to='/'>
              <Lin>Home</Lin>
            </Link>
            <Link to='/partyhall'>
              <Lin href='#'>Party Hall</Lin>
            </Link>

            <Link to='/contactus'>
              <Lin href='#'>Contact Us</Lin>
            </Link>
          </LinksContainer>
          <LinksContainer>
            <Lin></Lin>
            <Lin href='tel:+919902262999'>+91 9902262999</Lin>
            <Lin href='tel:+9198197006999'>+91 8197006999</Lin>
          </LinksContainer>

          <SocialLinksContainer>
            <SocialLink href='https://facebook.com/'>
              <FacebookIcon />
            </SocialLink>
            <SocialLink href='https://twitter.com/'>
              <img src='https://img.icons8.com/material-outlined/24/000000/instagram-new--v1.png' />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2021, DigitalBusinessPromoters.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  )
};
