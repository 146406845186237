import React,{useRef, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// import EmailIllustrationSrc from "images/team-illustration-3.svg";
import EmailIllustrationSrc from "images/contact-us-illustration.svg";
import emailjs from "emailjs-com"
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Select = tw.select`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Want to Book a <span tw='text-primary-500'>Party Hall </span>
      <wbr /> or Have Us <span tw='text-primary-500'>Catering..</span>
    </>
  ),
  description = (
    <>
      Feel free to <span tw='text-primary-500'>get in touch</span>
      <wbr /> with us.
    </>
  ),
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
const [name, setName] = useState()
const [date, setDate] = useState()
const [requirement, setRequirement] = useState()
const [mobile, setMobile] = useState()
const [quantity, setQuantity] = useState()
const [message, setMessage] = useState()
const form=useRef();

 const sendEmail = (e) => {
   e.preventDefault()

   emailjs
     .sendForm(
       "service_zgs9bik",
       "template_jqjyt3q",
       form.current,
       "user_qNsaCVd5D4bcBLAMbqzNj"
     )
     .then(
       (result) => {
         console.log(result.text)
       },
       (error) => {
         console.log(error.text)
       }
     )
 }
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} ref={form} onSubmit={sendEmail}>
              <Input
                type='text'
                name='mobile'
                value={mobile}
                onChange={(e) =>
                  e.target.value.length <= 10 &&
                  !isNaN(e.target.value) &&
                  setMobile(e.target.value)
                }
                placeholder='Mobile Number'
              />
              <Input
                type='text'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Full Name'
              />
              <Select onChange={(e) => setRequirement(e.target.value)}>
                <option value=''>---Your Requirement---</option>
                <option value='Party Hall'>Party Hall</option>
                <option value='Catering'>Catering</option>
              </Select>
              <Input
                type='text'
                name='quantity'
                value={quantity}
                onChange={(e) =>
                  e.target.value.length <= 4 &&
                  !isNaN(e.target.value) &&
                  setQuantity(e.target.value)
                }
                placeholder='Quantity'
              />
              <Input
                type='date'
                name='quantity'
                value={date}
                onChange={(e) =>
                  e.tasetDate(e.target.value)
                }
                placeholder='Date'
              />
              <Textarea name='message' value={message}onChange={e=>setMessage(e.target.value)} placeholder='Your Message Here' />
              <SubmitButton type='submit'disabled={!name || !mobile || !requirement }>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
